/* General section styling */
.patient-carousel {
  /* text-align: center; */
  padding: 40px 20px;
  background-color: #FFF5EC;
}

.carousel-heading {
  font-size: 50px;
  color: #004d40;
  margin-bottom: 20px;
  font-weight: 700;
  text-align: center;
}

/* Swiper customization */
.swiper-container {
  width: 100%;
}

.testimonial-card {
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: left;
  transition: transform 0.3s ease-in-out;
}

.testimonial-card:hover {
  transform: translateY(-10px);
}

.testimonial-image {
  width: 100%;
  /* height: 80px; */
  /* border-radius: 50%; */
  margin-bottom: 15px;
  object-fit: cover;
}

.testimonial-title {
  color: #00796b;
  font-size: 0.9em;
  margin-bottom: 10px;
}

.testimonial-tags {
  display: flex;
  /* margin: 10px 0; */
  /* gap: 0.2em; */
  align-items: start;
  flex-direction: column;
}

.tag {
  display: inline-block;
  background: #e0f2f1;
  color: #004d40;
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 0.8em;
  margin-right: 5px;
}

.tags {
  color: #179D82;
  border-radius: 20px;
  border: 2px solid #E9F8F5;
  padding: 5px 10px;
  margin-bottom: 10px;
  width: auto;
}

.testimonial-feedback {
  font-size: 0.95em;
  line-height: 1.5;
  color: #333;
  text-align: justify;
}


@media (max-width:768px) {

  /* General section styling */
  .patient-carousel {
    /* text-align: center; */
    padding: 40px 20px;
    background-color: #FFF5EC;
    width: auto;
  }

  .carousel-heading {
    font-size: 32px;
    color: #004d40;
    margin-bottom: 20px;
  }

  /* Swiper customization */
  .swiper-container {
    width: 100%;
  }

  .testimonial-card {
    background: #fff;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: left;
    transition: transform 0.3s ease-in-out;
  }

  .testimonial-card:hover {
    transform: translateY(-10px);
  }

  .testimonial-image {
    width: 100%;
    /* height: 80px; */
    /* border-radius: 50%; */
    margin-bottom: 15px;
    object-fit: cover;
  }

  .testimonial-title {
    color: #00796b;
    font-size: 0.9em;
    margin-bottom: 10px;
  }

  .testimonial-tags {
    /* display: flex; */
    /* flex-direction: column; */
    /* margin: 10px 0; */
    /* gap: 0.5em; */
  }

  .tag {
    display: inline-block;
    background: #e0f2f1;
    color: #004d40;
    padding: 5px 10px;
    border-radius: 20px;
    font-size: 0.8em;
    margin-right: 5px;
  }

  .tags {
    /* color: #179D82; */
    /* border-radius: 20px; */
    /* border: 2px solid #E9F8F5; */
    /* padding: 5px; */
    /* width: auto; */
  }

  .testimonial-feedback {
    font-size: 0.95em;
    line-height: 1.5;
    color: #333;
    text-align: justify;
  }
}

.review_card {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 4px #00000014;
  border: 1px solid #EAF1F9;
  border-radius: 20px;
  opacity: 1;
  padding: 1rem;
  position: relative;
  display: flex
;
  flex-direction: column;
  /* height: 100%; */
  height: 292px;
    margin-bottom: 20px;
}
.reviewcard_header {
  display: flex
;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
}

.reviewcard_image {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #ccc;
  display: flex
;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: #fff;
}

.reviewcard_detail {
  display: flex
;
  flex-direction: column;
  gap: 3px;
}

.reviewcard_name {
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0px;
  color: #363636;
  margin: 0;
  opacity: 1;
}

.reviewcard_star_thumb {
  display: flex
;
  align-items: center;
  gap: 20px;
}

.reviewcard_star_ratings {
  position: relative;
  box-sizing: border-box;
  display: flex
;
  gap: 3px;
  align-items: center;
}

.review_card_font {
  font-weight: normal;
  font-size: 12px;
  letter-spacing: 0px;
  color: #363636;
  opacity: 1;
}
.reviewcard_recommended {
  margin: 0;
}

.reviewcard_desc {
  font-weight: normal;
  font-size: 14px;
  letter-spacing: 0px;
  color: #363636;
  opacity: 1;
  margin: 18px 0;
  max-height: 125px;
  overflow-y: auto;
  text-align: justify;
}

.reviewcard_bottom {
  display: flex
;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #EEDDFF;
  padding-top: 10px;
  margin-top: auto;
}

.reviewcard_city, .reviewcard_doctor {
  font-weight: bold;
  font-size: 12px;
  letter-spacing: 0px;
  color: #6E6E6E;
  opacity: 1;
}

.reviewcard_city span {
  color: #ADADAD;
  font-weight: normal;
}

.carsouel_inner {
  flex-direction: row;
  flex-wrap: nowrap;
  list-style: none;
  padding: 0;
  transition: transform .4s cubic-bezier(.445, .05, .55, .95);
  margin-bottom: 1.5em;
  align-items: stretch !important;
}

.carsouel_item {
  flex: 0 0 100% !important;
  margin: 0;
}

.carsouel_inner .swiper-wrapper {
  flex-direction: row;
  flex-wrap: nowrap;
  list-style: none;
  padding: 0;
  transition: transform .4s cubic-bezier(.445, .05, .55, .95);
  margin-bottom: 1.5em;
  align-items: stretch !important;
}